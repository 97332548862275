import React from "react";
import { useTranslation } from "react-i18next";
import Layout from "../../components/layout/Layout";
import SEO from "../../components/seo/SEO";
import StorageDashboard from "../../components/storage-dashboard";

const StoragePage = () => {
  const { t } = useTranslation();
  return (
    <Layout>
      <SEO title={t("storage.title")} />
      <StorageDashboard />
    </Layout>
  );
};

export default StoragePage;
